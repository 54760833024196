import { navigate } from 'gatsby'
import React from 'react'

import paths from 'constants/paths'
import Utility from 'templates/Utility'

export default class ErrorNotFound extends React.Component {
  state = {}

  componentDidMount() {
    const { location } = this.props
    const { pathname } = location

    const latestSlug = '/latest/'

    if (pathname.includes(latestSlug)) {
      navigate(pathname.replace(latestSlug, ''))
    } else {
      this.setState({ failed: true })
    }
  }

  render() {
    const { failed } = this.state

    if (!failed) {
      return null
    }

    return (
      <Utility
        subtext="Darn, we couldn’t find what you’re looking for"
        button="Go to home"
        buttonTo={paths.HOME}
        className="ErrorNotFound">
        Sorry for the inconvenience.
      </Utility>
    )
  }
}
